<template>
  <div>
    <v-row class="pt-5 mt-3">
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/veranstaltung/${$route.params.veranstaltung}`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-clipboard-list</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-light">
          ANWESENHEITSLISTE
        </h3>
        <h2 class="white--text font-weight-bold">
          {{ veranstaltung.data.art }} {{ veranstaltung.data.mannschaft }}
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="veranstaltung.data">
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.beginn.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="2"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        BIS
      </v-col>
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.ende.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="10"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          Am
          <span class="font-weight-bold">
            {{ parseDate(veranstaltung.data.beginn.datum) }}
          </span>
          von
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
          bis
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }} Uhr
          </span>
        </h4>
      </v-col>
      <v-col cols="10" class="text-center" v-if="user.data.rolle == 'Vorstand'">
        <v-btn
          :color="template.colors.primary"
          @click="generatePDF()"
          :loading="generating"
          rounded
        >
          <v-icon class="mr-2">mdi-download</v-icon>
          PDF DOWNLOAD
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="rounded-xl">
          <v-toolbar :color="template.colors.primary">
            <v-row justify="space-between">
              <v-col cols="auto" align-self="center">
                <h3>{{ print_filter() }}</h3>
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn text rounded large @click="select_filter = true">
                  <v-icon class="mr-2">mdi-filter</v-icon>
                  Filter
                </v-btn>
                <v-dialog
                  :value="select_filter"
                  :content-class="
                    $vuetify.breakpoint.mdAndDown ? '' : 'rounded-xl'
                  "
                  :fullscreen="$vuetify.breakpoint.mdAndDown"
                  :persistent="$vuetify.breakpoint.lgAndUp"
                  transition="dialog-bottom-transition"
                  :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
                  dark
                  style="padding-top: env(safe-area-inset-top);"
                >
                  <v-card style="width: 100vw; overflow-x: hidden;">
                    <v-toolbar
                      fixed
                      :color="template.colors.primary"
                      style="
                        padding-top: env(safe-area-inset-top);
                        height: 80px + env(safe-area-inset-top);
                      "
                    >
                      <v-toolbar-title>Filter</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="save_filter()">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-row justify="center" class="mt-4">
                      <v-col cols="10" class="px-2 pb-0">
                        <v-btn
                          :color="
                            selected_filter.length == 0
                              ? '#63b32f'
                              : template.colors.blocks
                          "
                          :light="
                            selected_filter.length > 0
                              ? !template.isDark(template.colors.blocks)
                              : !template.isDark('#63b32f')
                          "
                          :dark="
                            selected_filter.length > 0
                              ? template.isDark(template.colors.blocks)
                              : template.isDark('#63b32f')
                          "
                          rounded
                          text
                          large
                          left
                          @click="
                            selected_filter.length == 0 ? remove(f) : add(f)
                          "
                        >
                          <v-icon large class="mr-2">
                            {{
                              selected_filter.length == 0
                                ? 'mdi-check-circle-outline'
                                : 'mdi-circle-outline'
                            }}
                          </v-icon>
                          Alle Personen
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="10"
                        class="px-2 pb-0"
                        v-for="(f, index) in filters"
                        :key="index"
                      >
                        <v-btn
                          :color="
                            selected_filter.includes(f)
                              ? '#63b32f'
                              : template.colors.blocks
                          "
                          :light="
                            selected_filter.includes(f)
                              ? !template.isDark(template.colors.blocks)
                              : !template.isDark('#63b32f')
                          "
                          :dark="
                            selected_filter.includes(f)
                              ? template.isDark(template.colors.blocks)
                              : template.isDark('#63b32f')
                          "
                          rounded
                          text
                          large
                          left
                          @click="
                            selected_filter.includes(f) ? remove(f) : add(f)
                          "
                        >
                          <v-icon large class="mr-2">
                            {{
                              selected_filter.includes(f)
                                ? 'mdi-check-circle-outline'
                                : 'mdi-circle-outline'
                            }}
                          </v-icon>
                          {{ f }}
                        </v-btn>
                      </v-col>
                      <v-col cols="10" class="px-2 mt-5 text-center">
                        <v-btn
                          rounded
                          x-large
                          :color="template.colors.primary"
                          @click="save_filter()"
                        >
                          <v-icon class="mr-2">mdi-content-save</v-icon>
                          Speichern
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-row
            style="min-height: 30vh;"
            justify="center"
            class="pa-4"
            v-if="anwesenheit.length == 0"
          >
            <v-col align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine
                {{
                  selected_filter.length == 0
                    ? 'Personen'
                    : print_filter('oder')
                }}
                erfasst.
              </h3>
            </v-col>
          </v-row>
          <v-list class="pa-4" two-line v-if="anwesenheit.length > 0">
            <v-list-item
              v-for="(m, index) in anwesenheit"
              :key="index"
              link
              :to="
                user.data.rolle == 'Vorstand'
                  ? `/veranstaltung/${veranstaltung.id}/anwesenheitsliste/${m.id}`
                  : undefined
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ m.person.vorname }} {{ m.person.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="m.verein">
                  {{ m.verein.name }}
                  {{ m.verein.team ? ' - ' + m.verein.team : '' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="selected_filter.length != 1">
                  {{ m.aktivitaet }}
                  <v-icon class="ml-3">
                    {{
                      translate_ggg(m.ggg) != ''
                        ? 'mdi-shield-check'
                        : 'mdi-shield-off-outline'
                    }}
                  </v-icon>
                  {{
                    translate_ggg(m.ggg) != ''
                      ? translate_ggg(m.ggg)
                      : 'Nicht immunisiert'
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import jsPDF from 'jspdf/dist/jspdf.es'
import autoTable from 'jspdf-autotable'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      anwesenheit: [],
      generating: false,
      selected_filter: [],
      select_filter: false,
      filters: [],
      tabs: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
      if (this.veranstaltung.data) {
        if (
          this.veranstaltung.data.art == 'Freundschaftsspiel' ||
          this.veranstaltung.data.art == 'Meisterschaftsspiel'
        ) {
          this.filters = [
            'Schiedsrichter',
            'Sportler',
            'Trainer',
            'Betreuer',
            'Zuschauer',
          ]
        } else {
          this.filters = ['Sportler', 'Trainer', 'Betreuer', 'Zuschauer']
        }
      }
      this.filter()
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    filter() {
      var i
      var liste = this.veranstaltung.anwesenheit

      this.anwesenheit = []

      if (this.selected_filter.length == 0) {
        liste.forEach((person) => {
          this.anwesenheit.push(person)
        })
      } else {
        if (this.selected_filter.includes('Schiedsrichter')) {
          liste.forEach((person) => {
            if (person.aktivitaet == 'Schiedsrichter') {
              this.anwesenheit.push(person)
            }
          })
        }
        if (this.selected_filter.includes('Sportler')) {
          liste.forEach((person) => {
            if (person.aktivitaet == 'Sportler') {
              this.anwesenheit.push(person)
            }
          })
        }
        if (this.selected_filter.includes('Trainer')) {
          liste.forEach((person) => {
            if (person.aktivitaet == 'Trainer') {
              this.anwesenheit.push(person)
            }
          })
        }
        if (this.selected_filter.includes('Betreuer')) {
          liste.forEach((person) => {
            if (person.aktivitaet == 'Betreuer') {
              this.anwesenheit.push(person)
            }
          })
        }
        if (this.selected_filter.includes('Zuschauer')) {
          liste.forEach((person) => {
            if (person.aktivitaet == 'Zuschauer') {
              this.anwesenheit.push(person)
            }
          })
        }
      }
    },
    save_filter() {
      this.filter()
      this.select_filter = false
    },
    add(filter) {
      this.selected_filter.push(filter)
    },
    remove(filter) {
      var filter_old = this.selected_filter
      this.selected_filter = []
      filter_old.forEach((f) => {
        if (f != filter) {
          this.selected_filter.push(f)
        }
      })
    },
    print_filter(word) {
      if (this.selected_filter.length == 0) {
        return 'Alle Personen'
      } else {
        var output = ''
        var count = 0
        this.selected_filter.forEach((filter) => {
          output += filter
          count++
          if (this.selected_filter.length > count) {
            if (this.selected_filter.length == count + 1) {
              output += ' ' + (word ? word : 'und') + ' '
            } else {
              output += ', '
            }
          }
        })
        return output
      }
    },
    generatePDF() {
      const doc = new jsPDF({ orientation: 'l', format: 'a4' })
      var index = 1
      var rows = []
      var headers = []
      var liste = []
      var veranstaltungsname = ''
      var datumtext = ''
      var typ = ''
      liste = this.anwesenheit
      if (this.veranstaltung.data.art == 'Wettkampf') {
        veranstaltungname = this.veranstaltung.data.name
      } else if (this.veranstaltung.data.art == 'Training') {
        veranstaltungsname = 'Training'
      } else if (
        this.veranstaltung.data.art == 'Freundschaftsspiel' ||
        this.veranstaltung.data.art == 'Meisterschaftsspiel' ||
        this.veranstaltung.data.art == 'Pokalspiel'
      ) {
        veranstaltungsname = 'Versammlung'
      } else {
        veranstaltungsname = this.veranstaltung.data.art
      }

      if (
        this.veranstaltung.data.beginn.datum !=
        this.veranstaltung.data.ende.datum
      ) {
        datumtext =
          this.parseDate(this.veranstaltung.data.beginn.datum) +
          ' bis ' +
          this.parseDate(this.veranstaltung.data.ende.datum)
      } else {
        datumtext = this.parseDate(this.veranstaltung.data.beginn.datum)
      }

      doc.text(
        this.user.data.verein.name +
          ' - ' +
          veranstaltungsname +
          '\n' +
          'Anwesenheitsliste (' +
          this.print_filter() +
          ') ' +
          datumtext,
        10,
        10,
      )
      rows = []
      headers = [
        [
          'Aktivität',
          'Name',
          'Vorname',
          'Adresse',
          'Telefon',
          'Zeitpunkt',
          'Info',
        ],
      ]
      liste.forEach((p) => {
        rows.push([
          p.aktivitaet,
          p.person.name,
          p.person.vorname,
          p.person.adresse + ', ' + p.person.plz + ' ' + p.person.ort,
          p.person.telefon,
          this.parseDate(p.zutritt.datum) +
            ' // ' +
            p.zutritt.uhrzeit +
            ' - ' +
            p.verlassen.uhrzeit,
          p.ggg ? this.translate_ggg(p.ggg) : '',
        ])
      })

      autoTable(doc, {
        head: headers,
        body: rows,
        theme: 'striped',
        headStyles: {
          fontStyle: 'bold',
          fillColor: this.template.colors.primary,
          textColor: this.template.colors.inline_primary_text,
        },
        startY: 30,
      })
      doc.save('Anwesenheitsliste_' + veranstaltungsname + '.pdf')
    },
    translate_ggg(ggg) {
      var str = ''
      if (ggg.getestet === true) {
        str += 'Negativ getestet'
      }
      if (ggg.genesen === true) {
        if (ggg.getestet === true) {
          str += ' & '
        }
        str += 'Genesen'
      }
      if (ggg.geimpft === true) {
        if (ggg.getestet === true) {
          str += ' & '
        }
        str += 'Geimpft'
      }
      return str
    },
  },
}
</script>
>
